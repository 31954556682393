import React, { ReactNode } from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import { Transaction } from "../../API/XFA_DEVICE_API";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AppIcon from "../../../images/General_XFA_app_icon.png";
import { useTranslation } from "react-i18next";
import {
  capitalizeFirstLetter,
  supportedMobileOSes,
} from "../../System/System";
import "../../General/general.css";
import { isMicrosoftAppsWebView } from "../utils/MicrosoftEAM";
import playStoreIcon from "../../../images/playstore.png";
import appStoreIcon from "../../../images/appstore.png";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";

interface ClientMissingSectionProps {
  completeTransaction: () => void;
  goToInstallation: () => void;
  customProtocolIOS: () => void;
  skip: () => void;
  transaction: Transaction | undefined;
  refreshTransaction: () => void;
  transactionIsSkippable: boolean | undefined;
  devicePreviouslyAffiliated: boolean | undefined;
  tokenStatus: string;
  os: string | undefined;
  organization: string | undefined;
  appNotInstalled: boolean | undefined;
}

export const ClientMissingSection: React.FC<ClientMissingSectionProps> = ({
  completeTransaction,
  goToInstallation,
  customProtocolIOS,
  skip,
  transaction,
  refreshTransaction,
  transactionIsSkippable,
  devicePreviouslyAffiliated,
  tokenStatus,
  os,
  organization,
  appNotInstalled,
}) => {
  const { t } = useTranslation();
  const [isAffiliated, setIsAffiliated] = React.useState<boolean>(false);
  const [isConfirmation, setIsConfirmation] = React.useState<boolean>(false);
  const [buttonText, setButtonText] = React.useState<string>("");
  const [content, setContent] = React.useState<ReactNode>(<></>);
  const [showSkipButton, setShowSkipButton] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsAffiliated(
      !(
        transaction?.decisions?.affiliated &&
        devicePreviouslyAffiliated !== true
      )
    );
    setIsConfirmation(tokenStatus === "Confirmation");
  }, [os, tokenStatus, transaction, devicePreviouslyAffiliated]);

  const unilinkNotSupported = React.useMemo(() => {
    // MicrosoftEAM on Android with Microsoft WebView should ask to open app manually when not in confirmation (so it tried the universal link)
    return (
      !isConfirmation &&
      transaction?.application?.type === "MicrosoftEAM" &&
      os === "Android" &&
      isMicrosoftAppsWebView(navigator.userAgent)
    );
  }, [os, transaction, isConfirmation]);

  const renderConfirmationContent = React.useCallback(
    () => (
      <>
        <Typography className="header">
          {transaction?.application
            ? t("Token.applicationRequiresSecurityCheckHeader")
            : transaction?.organization &&
              t("Token.verificationRequiresSecurityCheckHeader")}
        </Typography>
        {transaction?.application ? (
          <>
            <Typography variant="body1" className="information">
              {t("Token.applicationRequiresSecurityCheck1")}
            </Typography>
            <Typography variant="body1" className="information">
              {t("Token.applicationRequiresSecurityCheck2")}
            </Typography>
          </>
        ) : (
          transaction?.verification && (
            <>
              <Typography variant="body1" className="information">
                {t("Token.verificationRequiresSecurityCheck1")}
              </Typography>
              <Typography variant="body1" className="information">
                {t("Token.verificationRequiresSecurityCheck2")}
              </Typography>
            </>
          )
        )}
      </>
    ),
    [t, transaction]
  );

  const renderIsAppInstalledContent = React.useCallback(
    () => (
      <>
        <Typography className="header">
          {t("Token.applicationIsInstalledCheckHeader")}
          {unilinkNotSupported && (
            <>
              <br />
              {t("Token.applicationRequiresSecurityCheckUnilinkUnsupported")}
            </>
          )}
        </Typography>
        <Typography variant="body1" className="information">
          {transaction?.application
            ? t("Token.applicationIsInstalledCheckBody", {
                organization: organization ?? t("Token.organization"),
              })
            : transaction?.organization &&
              t("Token.verificationIsInstalledCheckBody", {
                organization: organization ?? t("Token.organization"),
              })}
        </Typography>
      </>
    ),
    [t, organization, unilinkNotSupported, transaction]
  );

  const renderNotAffiliatedContent = React.useCallback(
    () => (
      <>
        <Typography className="header">
          {transaction?.application
            ? t("Token.applicationRequiresAffiliationCheckHeader", {
                organization:
                  organization ??
                  capitalizeFirstLetter(t("Token.organization")),
              })
            : transaction?.verification &&
              t("Token.verificationRequiresAffiliationCheckHeader", {
                organization:
                  organization ??
                  capitalizeFirstLetter(t("Token.organization")),
              })}
        </Typography>
        {transaction?.application ? (
          <>
            <Typography variant="body1" className="information">
              {t("Token.applicationRequiresAffiliationCheck1")}
            </Typography>
            <Typography variant="body1" className="information">
              {t("Token.applicationRequiresAffiliationCheck2")}
            </Typography>
          </>
        ) : (
          transaction?.verification && (
            <>
              <Typography variant="body1" className="information">
                {t("Token.verificationRequiresAffiliationCheck1")}
              </Typography>
              <Typography variant="body1" className="information">
                {t("Token.verificationRequiresAffiliationCheck2")}
              </Typography>
            </>
          )
        )}
      </>
    ),
    [t, organization, transaction]
  );

  const [completingTransaction, setCompletingTransaction] =
    React.useState(false);

  const renderButtons = React.useCallback(() => {
    if (!os) {
      return;
    }

    const buttonString = buttonText;

    const handleButtonClick = () => {
      if (os === "iOS" && !isConfirmation) {
        customProtocolIOS();
      } else {
        setCompletingTransaction(true);
        completeTransaction();
      }
    };

    return (
      <>
        <div className="buttons">
          {isConfirmation && isAffiliated && (
            <div className="extra-information-container">
              <Typography className="extra-information">
                {t("Token.completeTransactionSubtext1")}
              </Typography>
            </div>
          )}
          {!unilinkNotSupported && (
            <div className="client-missing-button-container">
              <Button
                size="large"
                variant="contained"
                className="black-button"
                disabled={completingTransaction}
                onClick={handleButtonClick}
                endIcon={
                  completingTransaction && (
                    <CircularProgress
                      style={{
                        color: "inherit",
                        width: "1rem",
                        height: "1rem",
                      }}
                    />
                  )
                }
              >
                <Typography className={"install-text"}>
                  {t(buttonString)}
                </Typography>
              </Button>
              {appNotInstalled && (
                <Typography className="app-not-installed" color="error">
                  {t("Token.appNotInstalledMessage")}
                </Typography>
              )}
            </div>
          )}
          {!isConfirmation &&
            supportedMobileOSes.includes(os) &&
            (unilinkNotSupported ? (
              <>
                <Typography className="extra-information">
                  <div>{t("Token.dontHaveAppInstalled")}</div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: 4,
                    }}
                  >
                    <img className="installationIcon" src={AppIcon} alt="XFA" />
                    <ChevronRightIcon />
                    {os === "Android" && (
                      <img
                        src={playStoreIcon}
                        className="installationIcon"
                        alt="Play Store"
                      />
                    )}
                    {os === "iOS" && (
                      <img
                        src={appStoreIcon}
                        className="installationIcon"
                        alt="App Store"
                      />
                    )}
                  </div>
                  <div>
                    {os === "Android"
                      ? t("Token.searchForXFAInPlayStore")
                      : t("Token.searchForXFAInAppStore")}
                  </div>
                </Typography>
                <Typography className="extra-information">
                  <Button
                    size="large"
                    variant="outlined"
                    className="refresh-button"
                    onClick={refreshTransaction}
                    startIcon={<RefreshOutlinedIcon />}
                  >
                    <Typography className={"refresh-text"}>
                      {t(
                        "Token.refreshTransactionAfterManualVerificationButton"
                      )}
                    </Typography>
                  </Button>
                </Typography>
              </>
            ) : (
              <div className="button-container">
                <Button
                  startIcon={
                    <img className="smallIcon" src={AppIcon} alt="Ongoing" />
                  }
                  className="icon-button"
                  onClick={goToInstallation}
                  endIcon={<ChevronRightIcon />}
                >
                  <Typography className="install-text">
                    {t("Token.applicationIsInstalledCheckDenyButton")}
                  </Typography>
                </Button>
              </div>
            ))}
          {showSkipButton === true && (
            <Button className="skip-button" onClick={skip} size="small">
              <Typography className="skip-text">{t("Token.skip")}</Typography>
            </Button>
          )}
        </div>
      </>
    );
  }, [
    buttonText,
    refreshTransaction,
    completeTransaction,
    goToInstallation,
    isAffiliated,
    isConfirmation,
    os,
    skip,
    t,
    customProtocolIOS,
    completingTransaction,
    showSkipButton,
    unilinkNotSupported,
    appNotInstalled,
  ]);

  React.useEffect(() => {
    setShowSkipButton(transactionIsSkippable === true);
    if (!os) {
      return;
    }

    if (!supportedMobileOSes.includes(os) && !isConfirmation) {
      return;
    }

    let newButtonText = "";
    let newContent = <></>;

    if (supportedMobileOSes.includes(os) && !isConfirmation) {
      setShowSkipButton(false);
      newButtonText = "Token.applicationIsInstalledCheckAcceptButton";
      newContent = renderIsAppInstalledContent();
    } else if (isConfirmation) {
      if (isAffiliated) {
        newButtonText = "Token.completeTransaction";
        newContent = renderConfirmationContent();
      } else {
        newButtonText = "Token.completeAffiliationTransaction";
        newContent = renderNotAffiliatedContent();
      }
    }

    setButtonText(newButtonText);
    setContent(newContent);
  }, [
    os,
    isConfirmation,
    isAffiliated,
    renderIsAppInstalledContent,
    renderConfirmationContent,
    renderNotAffiliatedContent,
    showSkipButton,
    transactionIsSkippable,
    goToInstallation,
  ]);

  return (
    <div className="general">
      {content}
      {renderButtons()}
    </div>
  );
};
