import { Alert, Typography, Button } from "@mui/material";
import React, { useCallback } from "react";
import ErrorIcon from "@mui/icons-material/Error";

export interface Extension {
  installed: boolean | undefined;
  checking: boolean;
  recheck: () => void;
}

export interface ErrorSectionProps {
  t: (key: string) => string;
  error: any;
  extension?: Extension;
}

export const ErrorSection: React.FC<ErrorSectionProps> = ({
  t,
  error,
  extension,
}) => {
  const requestToken = useCallback(() => {
    if (extension) {
      extension.recheck();
      console.debug("testing:completingTransaction");
    }
  }, [extension]);

  return (
    <div className="error">
      <ErrorIcon className="ErrorIcon" />
      <Typography>{t("Token.error")}</Typography>
      {error && (
        <div className="alertContainer">
          <Alert className="alert" severity="error" icon={false}>
            {error}
          </Alert>
        </div>
      )}
      <div className="tryAgainContainer">
        <Button variant="outlined" className="tryAgain" onClick={requestToken}>
          <Typography>{t("Token.tryAgain")}</Typography>
        </Button>
      </div>
    </div>
  );
};
