import { Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

//Icons
import { openExtension } from "../../Extension/Extension";
import { goToTokenTransaction } from "../../Navigation/Navigation";
import { useNavigate } from "react-router-dom";
import XFAIcon from "../../../images/XFA_woordmerk_donkergrijs.svg";
//css
import "../../General/general.css";

export interface ExtensionInstalledSectionProps {
  browser: any;
  navigator: any;
  ongoingTransaction: boolean | undefined;
}

const ExtensionInstalledSection: React.FC<ExtensionInstalledSectionProps> = ({
  browser,
  navigator,
  ongoingTransaction,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="general">
      <img src={XFAIcon} alt="XFAIcon" className="squaredIcon" />
      <Typography className="header">{t(`Installation.finished1`)}</Typography>
      <Typography className="header">{t(`Installation.finished2`)}</Typography>
      <Typography className="body1">
        {t("Installation.finishedDescription", { name: browser })}
      </Typography>
      {ongoingTransaction ? (
        <Button
          variant="contained"
          className="black-button"
          onClick={() => {
            goToTokenTransaction(navigate);
          }}
        >
          <Typography>{t("Installation.returnToTransaction")}</Typography>
        </Button>
      ) : (
        <Button
          variant="contained"
          className="black-button"
          onClick={() => {
            openExtension(browser, navigator);
          }}
        >
          <Typography>{t("Installation.openExtension")}</Typography>
        </Button>
      )}
    </div>
  );
};

export default ExtensionInstalledSection;
