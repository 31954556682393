import {
  OS,
  TokenStatus,
  supportedMobileOSes,
  supportedDesktopOSes,
  checkOS,
  checkBrowser,
} from "../../System/System";
import { Transaction } from "../../API/XFA_DEVICE_API";

export const transactionCompletion = (
  transaction: Transaction | undefined,
  supported: boolean | undefined,
  extension: any,
  devicePreviouslyAffiliated: boolean | undefined,
  setTokenStatus: (status: TokenStatus) => void,
  tokenStatus: TokenStatus,
  attemptedAutoCompletingTransaction: boolean,
  setAttemptedAutoCompletingTransaction: (attempted: boolean) => void,
  endTransaction: (transaction: Transaction, os: OS) => void,
  completeTransaction: (
    transaction: Transaction,
    supported: boolean | undefined
  ) => void
) => {
  const os = checkOS(window.navigator);
  const browser = checkBrowser(window.navigator);
  if (attemptedAutoCompletingTransaction) {
    return;
  }

  //make sure os is known
  if (!os || !transaction) {
    return;
  }

  if (transaction.status === "FILTERED" && transaction.access_token) {
    endTransaction(transaction, os);
    return;
  }

  if (transaction.status === "PENDING") {
    if (supportedMobileOSes.includes(os)) {
      // Warning to future self: Do not automatically redirect, won't trigger the deeplink
      //if already on token-mobile page, app is not installed
      const url = new URL(window.location.href);

      if (url.pathname === "/token-mobile") {
        //Android should return to web baseurl to retrigger the deeplink, when installed
        if (
          os === "Android" &&
          !url.href.startsWith(process.env.REACT_APP_BASEURL!)
        ) {
          //Note: important to check for negative of basurl (not mobile url) to prevent infinite loop in tests
          const redirectUrl = new URL(
            "/token-mobile",
            process.env.REACT_APP_BASEURL
          );
          redirectUrl.searchParams.set(
            "transactionId",
            transaction.transactionId
          );
          window.location.assign(redirectUrl.toString());
        }
        //iOS should stay to 'open' and grant automatic deeplink permission to the app
        setTokenStatus("ClientMissing");
        return;
      }
      setTokenStatus("Confirmation");
    } else if (
      supportedDesktopOSes.includes(os) ||
      (os === "UNIX" && supported === true) ||
      (browser === "Safari" && supported === true)
    ) {
      //wait till extension is installed
      if (
        extension.installed === undefined ||
        (devicePreviouslyAffiliated === undefined &&
          extension.installed !== false)
      ) {
        setTokenStatus("Checking");
        return;
      } else if (!extension.installed) {
        setTokenStatus("ClientMissing");
        return;
      }

      //if no affiliation is needed, complete immediately
      if (
        transaction.decisions?.affiliated?.status === "OK" ||
        devicePreviouslyAffiliated
      ) {
        setAttemptedAutoCompletingTransaction(true);
        completeTransaction(transaction, supported);
        return;
      }
      //if device already affiliated with organization, complete immediately
      if (transaction.decisions?.affiliated && devicePreviouslyAffiliated) {
        setAttemptedAutoCompletingTransaction(true);
        completeTransaction(transaction, supported);
        return;
      }

      if (tokenStatus === "Error") {
        return;
      }

      //ask for confirmation in all the other cases
      setTokenStatus("Confirmation");
    }
  }
};
