const oses = [
  "Windows",
  "macOS",
  "UNIX",
  "Linux",
  "Android",
  "iOS",
  "iPadOS",
  "ChromeOS",
] as const;
export type OS = typeof oses[number];

const statuses = [
  "Checking",
  "ClientMissing",
  "Confirmation",
  "OK",
  "NOK",
  "Error",
] as const;
export type TokenStatus = typeof statuses[number];

const browsers = [
  "Chrome",
  "Edge",
  "Safari",
  "Firefox",
  "Opera",
  "IE",
  "Brave",
];
export type Browser = typeof browsers[number];

export const supportedDesktopOSes = ["macOS", "Windows"];
export const supportedMobileOSes: string[] = ["iOS", "Android", "iPadOS"];
export const supportedBrowsers = ["Chrome", "Firefox", "Edge", "Brave"];

export const checkOS = (navigator: any) => {
  if (navigator.appVersion.indexOf("Android") !== -1) return "Android" as OS;
  if (navigator.appVersion.indexOf("iPad") !== -1) return "iPadOS" as OS;
  if (
    (navigator.appVersion.indexOf("Mobile") !== -1 &&
      navigator.appVersion.indexOf("Mac") !== -1) ||
    ((navigator.appVersion.indexOf("iPhone") !== -1 ||
      navigator.appVersion.indexOf("iPad") !== -1) &&
      navigator.appVersion.indexOf("Mac") !== -1)
  )
    return "iOS" as OS;
  if (navigator.appVersion.indexOf("Win") !== -1) return "Windows" as OS;
  if (navigator.appVersion.indexOf("Mac") !== -1) return "macOS" as OS;
  if (navigator.appVersion.indexOf("X11") !== -1) return "Linux" as OS;
  if (navigator.appVersion.indexOf("Linux") !== -1) return "Linux" as OS;
  if (navigator.appVersion.indexOf("CrOS") !== -1) return "ChromeOS" as OS;
};

export const checkBrowser = (navigator: any) => {
  const ua = navigator.userAgent;
  let tem;
  let M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return "IE" as Browser;
  }

  if (M[1] === "Chrome") {
    tem = ua.match(/\b(OPR|Edge|Edg)\/([0-9.]+)/);
    if (tem != null)
      if (tem[0] === "OPR") {
        return "Opera" as Browser;
      } else {
        return "Edge" as Browser;
      }
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);

  if (M[0] === "Chrome") {
    return "Chrome" as Browser;
  }
  if (M[0] === "Safari") {
    return "Safari" as Browser;
  }
  if (M[0] === "Firefox") {
    return "Firefox" as Browser;
  }
};

export function capitalizeFirstLetter(string: string) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export const playStoreLink =
  "https://play.google.com/store/apps/details?id=tech.xfa.xfa_native_mobile_application";
export const appStoreLink = "https://apps.apple.com/be/app/xfa/id1630086940";
export const mozillaExtensionLink =
  "https://addons.mozilla.org/en-US/firefox/addon/xfa-securing-your-device/";
export const chromeExtensionLink =
  "https://chromewebstore.google.com/detail/xfa-discover-true-optimal/ajobakkejbhbmiiodbcmobomohngjdhc";
export const edgeExtensionLink =
  "https://microsoftedge.microsoft.com/addons/detail/xfa-securing-your-device/ikhelogcjkfnfccibbceakadlkbilhbg";
