import { useCallback } from "react";
import { Transaction } from "../../API/XFA_DEVICE_API";
import {
  checkOS,
  OS,
  supportedDesktopOSes,
  supportedMobileOSes,
  TokenStatus,
} from "../../System/System";
import { isMicrosoftAppsWebView } from "../utils/MicrosoftEAM";
import { sendCompleteTransactionToExtension } from "../../Extension/Extension";
import { useTranslation } from "react-i18next";

export const useCompleteTransaction = (
  setTokenStatus: (status: TokenStatus) => void,
  browser: any,
  navigator: any,
  setError: (error: string) => void,
  updateTransaction: (transactionId: string) => void,
  setCompletingTransaction: (completing: boolean) => void,
  endTransaction: (transaction: Transaction) => void
) => {
  const { t } = useTranslation();
  const userAgent = window.navigator.userAgent;

  const handleMobileTransaction = useCallback(
    (transaction: Transaction, os: OS) => {
      if (
        transaction.application?.type === "MicrosoftEAM" &&
        os === "Android" &&
        isMicrosoftAppsWebView(userAgent)
      ) {
        navigator.clipboard.writeText(
          `xfa:transaction/${transaction.transactionId}`
        );
      }

      const redirectUrl = new URL(
        "/token-mobile",
        process.env.REACT_APP_MOBILE_BASEURL
      );
      redirectUrl.searchParams.set("transactionId", transaction.transactionId);

      if (window.location.href.includes("token-mobile")) {
        redirectUrl.searchParams.set("appNotInstalled", "true");
      }

      window.location.replace(redirectUrl.toString());
    },
    [userAgent, navigator.clipboard]
  );

  const handleDesktopTransaction = useCallback(
    (transaction: Transaction, os: OS, supported: boolean | undefined) => {
      const isSupportedDesktop =
        supportedDesktopOSes.includes(os) ||
        (os === "UNIX" && supported) ||
        (browser === "Safari" && supported);

      if (isSupportedDesktop) {
        try {
          setCompletingTransaction(true);
          console.log("sending transaction to extension");
          sendCompleteTransactionToExtension(
            browser,
            navigator,
            transaction.transactionId,
            (reply) => {
              updateTransaction(transaction.transactionId);
              setCompletingTransaction(false);

              if (reply.message?.includes("error")) {
                setTokenStatus("Error");
                setError(reply.message);
                throw new Error(reply.message);
              }
            }
          );
        } catch (error) {
          setTokenStatus("Error");
          console.log("error: ", error);
          setError(t("Token.errors.sendingTransactionToExtensionFailed"));
          setCompletingTransaction(false);
        }
      } else {
        setTokenStatus("Error");
        setError(t("Device not supported"));
      }
    },
    [
      setCompletingTransaction,
      updateTransaction,
      t,
      setError,
      setTokenStatus,
      browser,
      navigator,
    ]
  );

  return useCallback(
    (transaction: Transaction, supported: boolean | undefined) => {
      console.log("completeTransaction", transaction, supported);
      const os = checkOS(window.navigator);
      if (!os) {
        setTokenStatus("Error");
        setError(t("OS not known"));
        return;
      }

      if (!transaction) {
        setTokenStatus("Error");
        setError(t("Transaction not present"));
        return;
      }

      if (transaction.status === "FILTERED") {
        endTransaction(transaction);
        return;
      }

      if (supportedMobileOSes.includes(os)) {
        handleMobileTransaction(transaction, os);
      } else {
        handleDesktopTransaction(transaction, os, supported);
      }
    },
    [
      t,
      setTokenStatus,
      setError,
      endTransaction,
      handleDesktopTransaction,
      handleMobileTransaction,
    ]
  );
};
