import { Transaction } from "../../API/XFA_DEVICE_API";
import { checkDeviceAffiliatedWithOrganization } from "../../Extension/Extension";
import { TokenStatus } from "../../System/System";

export const checkAffiliationConditions = (
  devicePreviouslyAffiliated: boolean | undefined,
  transaction: Transaction | undefined,
  extensionInstalled: boolean | undefined,
  setDevicePreviouslyAffiliated: (affiliated: boolean) => void,
  setTokenStatus: (status: TokenStatus) => void,
  setError: (message: string) => void,
  browser: any,
  navigator: Navigator,
  t: (key: string) => string
) => {
  //only do it once (while devicePreviouslyAffiliated === undefined)
  if (devicePreviouslyAffiliated !== undefined) {
    return;
  }

  //make sure transaction is present
  if (!transaction) {
    return;
  }

  //only handle when transaction still pending
  if (transaction.status !== "PENDING") {
    return;
  }

  //only check when extension is installed
  if (!extensionInstalled) {
    return;
  }

  //only check if there is a requirement to be affiliated
  if (
    transaction?.decisions?.affiliated &&
    transaction?.organization?.organizationId
  ) {
    try {
      checkDeviceAffiliatedWithOrganization(
        browser,
        navigator,
        transaction.organization!.organizationId,
        (affiliated) => {
          if (affiliated === true) {
            setDevicePreviouslyAffiliated(true);
          } else {
            setDevicePreviouslyAffiliated(false);
          }
        }
      );
    } catch (error) {
      console.error("Error checking affiliation", error);
      setDevicePreviouslyAffiliated(false);
      setTokenStatus("Error");
      setError(t("Token.errors.affiliation"));
    }
  }
};
