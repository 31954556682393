import React, { useState } from "react";
import DecisionListItem from "./DecisionListItem";
import UpdateInstructionsModal from "./UpdateInstructionsModal"; // Import the modal component
import { OS, checkOS } from "../../../System/System";
import { openExtension } from "../../../Extension/Extension";

interface StatusDecisionListItemProps {
  navigator: any;
  browser: any;
  message: string;
  buttonText: string;
  showModal: boolean;
  blocking: boolean;
  timeTillBlocked?: number;
}

const StatusDecisionListItem: React.FC<StatusDecisionListItemProps> = ({
  navigator,
  browser,
  message,
  buttonText,
  showModal,
  blocking,
  timeTillBlocked,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const os: OS | null = checkOS(navigator) ?? null;

  const handleActionClick = () => {
    if (showModal) {
      setIsModalOpen(true);
    } else {
      if (os === "Android") {
        const redirectUrl = new URL(
          "/token-mobile",
          process.env.REACT_APP_MOBILE_BASEURL
        );
        window.location.assign(redirectUrl.href);
      } else if (os === "iOS") {
        window.location.assign("xfaapp://");
      } else {
        openExtension(browser, navigator);
      }
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <DecisionListItem
        message={message}
        buttonText={buttonText}
        blocking={blocking}
        onActionClick={handleActionClick}
        timeTillBlocked={timeTillBlocked}
      />

      <UpdateInstructionsModal
        platform={os}
        open={isModalOpen}
        handleClose={handleCloseModal}
      />
    </>
  );
};

export default StatusDecisionListItem;
