import React, { useEffect } from "react";
import useBrowserExtensionStatus from "../Extension/Extension";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import XFAIcon from "../../images/XFA_woordmerk_donkergrijs.svg";

import {
  appStoreLink,
  OS,
  playStoreLink,
  supportedMobileOSes,
  TokenStatus,
} from "../System/System";
import { setupDeferredDeepLinkForLatestInvitation } from "../Deeplinks/Deeplinks";
import { goToInstallationFromTokenTransaction } from "../Navigation/Navigation";

import {
  IntercomOverlay,
  updateIntercomProps,
} from "../intercom/intercomProvider";
import SetFavicon from "../General/SetFavicon";
import TopBar from "../General/TopBar";

// utils
import {
  goToInstallationStep,
  redirectWithProtocolOnIos,
} from "./utils/redirectHelpers";
import { checkAffiliationConditions } from "./utils/affiliationConditions";
import { transactionCompletion } from "./utils/transactionCompletion";
import { handleUnsupportedTransaction } from "./utils/handleUnsupportedTransaction";
import { handleCompletedTransaction } from "./utils/handleCompletedTransaction";
//hooks
import useTransaction from "./hooks/useTransaction";
import { useDeviceSupport } from "./hooks/useDeviceSupport";
import { useSkipTransaction } from "./hooks/useSkipTransaction";
import { useCompleteTransaction } from "./hooks/useCompleteTransaction";
import { useEndTransaction } from "./hooks/useEndTransaction";
import { useMergeShadows } from "./hooks/useMergeShadows";
// sections
import CheckingSection from "./sections/CheckingSection";
import { ClientMissingSection } from "./sections/ClientMissingSection";
import { RedirectingSection, VerifiedSection } from "./sections/SuccessSection";
import { ErrorSection, Extension } from "./sections/ErrorSection";
import { NokSection } from "./sections/NokSection";
import { ConnectSection } from "../Installation/sections/ConnectSection";
// css
import "./Token.css";
import "../General/general.css";

export interface TokenProps {
  navigator: any;
  browser: any;
}

export interface TokenRequestCustomFlow {
  redirectUrl: string;
  applicationId?: string;
  applicationDomain?: string;
  email?: string;
  requested: number;
}

const Token: React.FC<TokenProps> = (props: TokenProps) => {
  const { t } = useTranslation();
  const extension: Extension = useBrowserExtensionStatus(
    props.browser,
    props.navigator
  );
  const { os, browser, supported } = useDeviceSupport(
    props.navigator,
    extension
  );

  const [mfa, setMfa] = React.useState<boolean>(false);
  const [showConnectSection, setShowConnectSection] =
    React.useState<boolean>(false);
  const [showClientMissingSection, setShowClientMissingSection] =
    React.useState<boolean>(false);
  const [error, setError] = React.useState<string>("");
  const [tokenStatus, setTokenStatus] = React.useState<TokenStatus>("Checking");
  const [appNotInstalled, setAppNotInstalled] = React.useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [
    attemptedAutoCompletingTransaction,
    setAttemptedAutoCompletingTransaction,
  ] = React.useState(false);

  const [devicePreviouslyAffiliated, setDevicePreviouslyAffiliated] =
    React.useState<boolean | undefined>(undefined);
  const {
    transaction,
    updateTransaction,
    transactionError,
    setTransaction,
    transactionIsSkippable,
  } = useTransaction(props.navigator);
  const [completingTransaction, setCompletingTransaction] =
    React.useState<boolean>(false);

  const [transactionAllowsUnsupported, setTransactionAllowsUnsupported] =
    React.useState<boolean | undefined>(undefined);

  //manage error to show
  React.useEffect(() => {
    if (transactionError) {
      setError(transactionError);
      setTokenStatus("Error");
    }
  }, [setError, setTokenStatus, transactionError]);

  //check if transaction allows unsupported devices
  React.useEffect(() => {
    if (!transaction) {
      return;
    }

    if (transaction.status === "PENDING") {
      if (transaction.decisions?.unsupported?.allowed === true) {
        setTransactionAllowsUnsupported(true);
      }
    }
  }, [transaction]);

  //check if app is not installed -> show not installed button on mobile
  React.useEffect(() => {
    const appNotInstalledParam = searchParams.get("appNotInstalled");
    if (appNotInstalledParam) {
      setAppNotInstalled(appNotInstalledParam === "true");
    }
  }, [searchParams]);

  const endTransaction = useEndTransaction(setTokenStatus, setMfa);
  const completeTransaction = useCompleteTransaction(
    setTokenStatus,
    props.browser,
    props.navigator,
    setError,
    updateTransaction,
    setCompletingTransaction,
    endTransaction
  );

  const mergeShadows = useMergeShadows();

  //redirect immediately if not supported
  useEffect(() => {
    if (transactionAllowsUnsupported) {
      if (supported === false && transaction) {
        handleUnsupportedTransaction(
          transaction,
          setTokenStatus,
          setError,
          t,
          endTransaction
        );
      }
    }
  }, [
    supported,
    transactionAllowsUnsupported,
    transaction,
    setTokenStatus,
    setError,
    t,
    props.navigator.userAgent,
    endTransaction,
  ]);

  // Functie om de transactiestatus te verversen
  const refreshTransactionStatus = () => {
    if (!transaction || !transaction.transactionId) {
      console.error("Geen transactie ID beschikbaar voor refresh");
      return;
    }
    console.debug("testing:completingTransaction");

    completeTransaction(transaction, supported);
  };

  //check if device already affiliated
  useEffect(() => {
    checkAffiliationConditions(
      devicePreviouslyAffiliated,
      transaction,
      extension.installed,
      setDevicePreviouslyAffiliated,
      setTokenStatus,
      setError,
      props.browser,
      props.navigator,
      t
    );
  }, [
    extension.installed,
    props.browser,
    props.navigator,
    transaction,
    devicePreviouslyAffiliated,
    t,
    setDevicePreviouslyAffiliated,
    setTokenStatus,
    setError,
  ]);

  React.useEffect(() => {
    console.debug("tokenStatus: " + tokenStatus);
  }, [tokenStatus]);

  //attempt to complete transaction or guide the user
  useEffect(() => {
    transactionCompletion(
      transaction,
      supported,
      extension,
      devicePreviouslyAffiliated,
      setTokenStatus,
      tokenStatus,
      attemptedAutoCompletingTransaction,
      setAttemptedAutoCompletingTransaction,
      endTransaction,
      completeTransaction
    );
  }, [
    transaction,
    supported,
    extension,
    devicePreviouslyAffiliated,
    setTokenStatus,
    tokenStatus,
    attemptedAutoCompletingTransaction,
    setAttemptedAutoCompletingTransaction,
    endTransaction,
    completeTransaction,
    setError,
    updateTransaction,
    setCompletingTransaction,
    setMfa,
  ]);

  //forward after showing decision for a short period of time
  useEffect(() => {
    if (!transaction) return;
    handleCompletedTransaction(
      transaction,
      mergeShadows,
      setTokenStatus,
      endTransaction
    );
  }, [
    transaction,
    mergeShadows,
    setTokenStatus,
    navigate,
    setMfa,
    endTransaction,
  ]);

  const skip = useSkipTransaction(
    transaction,
    setError,
    setTokenStatus,
    setTransaction,
    endTransaction
  );

  React.useEffect(() => {
    setShowConnectSection(
      tokenStatus === "ClientMissing" && !supportedMobileOSes.includes(os as OS)
    );
    setShowClientMissingSection(
      (tokenStatus === "ClientMissing" || tokenStatus === "Confirmation") &&
        !showConnectSection &&
        supported === true
    );
  }, [
    os,
    showConnectSection,
    supported,
    tokenStatus,
    transaction?.decisions?.mfa?.status,
  ]);

  React.useEffect(() => {
    updateIntercomProps(
      transaction?.user.email,
      transaction?.organization?.name,
      transaction?.transactionId
    );
  }, [transaction]);

  return (
    <>
      {transaction?.application?.iconUrl && transaction.application.name && (
        <SetFavicon
          title={transaction.application.name}
          url={transaction?.application?.iconUrl}
        />
      )}
      <div className="root">
        <IntercomOverlay>
          <TopBar transaction={transaction} signIn={true} />
          <div className="content-container">
            <div className="content-token">
              {!showConnectSection && (
                <div className="general">
                  <img src={XFAIcon} alt="XFAIcon" className="xfaIcon" />
                </div>
              )}
              {tokenStatus === "Checking" && (
                <CheckingSection
                  t={t}
                  skip={skip}
                  transactionIsSkippable={transactionIsSkippable}
                  deviceCheck={false}
                  transaction={transaction}
                />
              )}
              {showConnectSection && (
                <ConnectSection
                  goToInstallation={() => {
                    goToInstallationStep(transaction!, os!, browser, navigate);
                  }}
                  organization={transaction?.organization?.name}
                  organizationIconUrl={transaction?.organization?.logoUrl}
                  mobile={(os && supportedMobileOSes.includes(os)) ?? false}
                  invited={false}
                  isSkippable={transactionIsSkippable}
                  skip={skip}
                  demo={transaction?.application === undefined}
                />
              )}
              {showClientMissingSection && (
                <ClientMissingSection
                  completeTransaction={() => {
                    if (!transaction) return;
                    completeTransaction(transaction, supported);
                  }}
                  goToInstallation={async () => {
                    if (!transaction) return;
                    if (os === "iOS") {
                      await setupDeferredDeepLinkForLatestInvitation(); //can only be done in response to a user interation
                      console.debug("testing:redirectedToIOSAppStore");
                      window.open(appStoreLink, "_self");
                    } else if (os === "Android") {
                      await setupDeferredDeepLinkForLatestInvitation(); //can only be done in response to a user interation
                      window.open(playStoreLink, "_self");
                    } else {
                      goToInstallationFromTokenTransaction(
                        navigate,
                        transaction?.transactionId
                      );
                    }
                  }}
                  customProtocolIOS={() =>
                    redirectWithProtocolOnIos(transaction!)
                  }
                  skip={skip}
                  transaction={transaction}
                  refreshTransaction={() => {
                    updateTransaction(transaction?.transactionId!);
                  }}
                  transactionIsSkippable={transactionIsSkippable}
                  tokenStatus={tokenStatus}
                  devicePreviouslyAffiliated={devicePreviouslyAffiliated}
                  os={os}
                  organization={transaction?.organization?.name}
                  appNotInstalled={appNotInstalled}
                />
              )}
              {tokenStatus === "OK" && !mfa && (
                <>
                  {transaction?.application ? (
                    <RedirectingSection t={t} />
                  ) : (
                    transaction?.organization && (
                      <VerifiedSection
                        t={t}
                        organization={transaction?.organization}
                      />
                    )
                  )}
                </>
              )}

              {tokenStatus === "Error" && (
                <ErrorSection t={t} extension={extension} error={error} />
              )}
              {tokenStatus === "NOK" && (
                <NokSection
                  t={t}
                  browser={props.browser}
                  navigator={props.navigator}
                  transaction={transaction}
                  endTransaction={() => {
                    if (!transaction) return;
                    endTransaction(transaction);
                  }}
                  refreshTransactionStatus={refreshTransactionStatus}
                  refreshingTransaction={completingTransaction}
                />
              )}
              {tokenStatus === null && (
                <CheckingSection
                  t={t}
                  skip={skip}
                  transactionIsSkippable={false}
                  deviceCheck={true}
                  transaction={transaction}
                />
              )}
            </div>
          </div>
        </IntercomOverlay>
      </div>
    </>
  );
};

export default Token;
